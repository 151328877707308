import axios from './axios';
import Cookies from 'js-cookie';

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    axios.defaults.headers.common.Token = `Bearer ${accessToken}`;
  } else {
    removeCookie();
    delete axios.defaults.headers.common.Token;
  }
};

export const getCookie = () => {
  return Cookies.get('accessToken');
};

export const setCookie = (accessToken: string) => {
  Cookies.set('accessToken', accessToken, { expires: 1 });
};

export const removeCookie = () => {
  Cookies.remove('accessToken');
};
