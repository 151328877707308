import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState, forwardRef } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Alert,
  useTheme,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Slide from "@mui/material/Slide";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "../../components/hook-form";
import Iconify from "../../components/Iconify";
import { OutlinedInput } from "@mui/material";
import Label from "../../components/Label";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { useDispatch } from "../../redux/store";
import { setLoginTest, setUser } from "../../redux/slices/user";
import { LoginUser } from "../../services/userApiServiceClickadu";
import { setCookie, setSession } from "../../utils/jwt";
import { UserProfile } from "../../types/user";
import { AuthState } from "../../types/auth";

//------------------------------------------------
type FormValuesProps = {
  user_name: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {

  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState<null | {message:string}>(null)

  const LoginSchema = Yup.object().shape({
    user_name: Yup.string().required("User name is required"),
    password: Yup.string().required("Password is required"),
  });

  const dispatch = useDispatch();

  const defaultValues: FormValuesProps = {
    user_name: "",
    password: "",
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema) as any,
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const respons = await LoginUser.login({
        user_name: data.user_name,
        password: data.password,//aswwed2fhh84dgk4xe1
      });
      setCookie(respons.token)
      setSession(respons.token);
      const userProfile: UserProfile = {
        id: respons.user.id,
        user_name: respons.user.user_name,
      };
      const _setUser: AuthState = {
        userProfile:userProfile,
      };
      dispatch(setUser(_setUser));
      // dispatch(setUser(respons.user))
    } catch (error:any) {
      console.error(error);
      setErrors({ message: error?.data?.message || "Internal server error" });
      // reset();
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {errors && (
          <Alert severity="error">{errors.message}</Alert>
        )}

        <RHFTextField name="user_name" label="User name" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label="Remember me" />
        {/* <Link component={RouterLink} variant="subtitle2" to={""}>
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
