import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
// layouts
import DashboardLayout from "../layouts/dashboard";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// config
import { PATH_AFTER_LOGIN } from "../config/config";
// components
import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = !!pathname.includes("/dashboard");
  console.log(isDashboard)

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // auth routes
    {
      path: "login",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {path:"dashboard/home",element:<HomeDasboard/>},
        { path: "dashboard/create", element: <Create /> },
        { path: "dashboard/create/:productName", element: <Create /> },
        { path: "dashboard/plans", element: <Plans /> },
        { path: "dashboard/product", element: <Products /> },
        { path: "dashboard/new-user", element: <NewUser /> },
        { path: "dashboard/create-key", element: <NewKey /> },
        { path: "dashboard/block-list", element: <BlockPage /> },
        // {
        //   path: "setting",
        //   children: [
        //     { element: <Navigate to="setting/theme" replace />, index: true },
        //     { path: "theme", element: <ThemePage /> },
        //   ],
        // },
      ],
    },
    {
      path: "/doc",
      element: <MainLayout />,
      children: [{ element: <HomePage />, index: true }],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));

// DASHBOARD
// GENERAL
const Document = Loadable(lazy(() => import("../pages/dashboard/Document")));
const Create = Loadable(lazy(() => import("../pages/dashboard/CreatePage")));
const Plans = Loadable(lazy(() => import("../pages/dashboard/PlansPage")));
const Products = Loadable(lazy(() => import("../pages/dashboard/ProductPage")));
const NewUser = Loadable(lazy(() => import("../pages/dashboard/NewUser")));
const NewKey = Loadable(lazy(() => import("../pages/dashboard/NewKey")));
const BlockPage = Loadable(lazy(() => import("../pages/dashboard/BlockListPage")));
const HomeDasboard = Loadable(lazy(()=>import('../pages/dashboard/Home')));
// setting
const ThemePage = Loadable(lazy(() => import("../pages/dashboard/ThemePage")));
//
const HomePage = Loadable(lazy(() => import("../pages/HomePage")));

// MAIN
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
// const Page500 = Loadable(lazy(() => import("../pages/Page500")));
// const Page403 = Loadable(lazy(() => import("../pages/Page403")));
