import { createSlice } from '@reduxjs/toolkit';
// @types
import { AuthState } from '../../types/auth';
import { setSession } from '../../utils/jwt';

// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  error: null,
  userProfile: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    hasError(state, action: { payload: any }) {
      state.error = action.payload;
      state.isInitialized = true;
    },

    setUser(state, action: { payload: AuthState }) {
      console.log(action.payload)
      state.isAuthenticated = true;
      state.userProfile = action.payload.userProfile;
      state.isInitialized = true;
    },

    setInitialize(state, action: { payload: boolean }) {
      state.isInitialized = action.payload;
    },

    resetUser(state) {
      state.userProfile = null;
      state.isAuthenticated = false;
      state.isInitialized = true;
    },

    logout(state) {
      setSession(null);
      state.userProfile = null;
      state.isAuthenticated = false;
      state.isInitialized = true;
    },
    setLoginTest(state, action: { payload: boolean }){
      state.isAuthenticated = action.payload;
      state.isInitialized = true;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { hasError, setUser, setInitialize, resetUser, setLoginTest } = slice.actions;