import axios, { AxiosError } from 'axios'

const REACT_APP_HOST_API="https://smmthunder.com/"
// const REACT_APP_HOST_API="http://127.0.0.1:8000/"
// const REACT_APP_HOST_API="http://65.108.4.101:8009/"

const axiosInstance = axios.create({ baseURL: REACT_APP_HOST_API });

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['Accept'] = 'application/json';

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    console.log(error);
    return Promise.reject(
      (error.response && { status: error.response.status, data: error.response.data }) ||
        'there is a problem'
    );
  }
);

export default axiosInstance;
