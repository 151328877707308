// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function UserAvatar({ ...other }: AvatarProps) {
  const { userProfile: user } = useAuth();

  return (
    <Avatar
      src={''}
      alt={user?.user_name || ''}
      color={'default'}
      {...other}
    >
      {createAvatar(user?.user_name || '').name}
    </Avatar>
  );
}
