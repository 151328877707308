import { useLocation, Outlet } from "react-router-dom";
// @mui
import { Box, Link, Container, Typography, Stack } from "@mui/material";
// components
import Logo from "../../components/Logo";
//
// import MainFooter from './MainFooter';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === "/";

  return (
    <Stack sx={{ minHeight: 1 }}>
      {/* <MainHeader /> */}

      <Outlet />

      
    </Stack>
  );
}
