import { ReactNode, useEffect } from "react";
import { getCookie, removeCookie, setCookie, setSession } from "../utils/jwt";
import { LoginUser } from "../services/userApiServiceClickadu";
import { UserProfile } from "../types/user";
import { useDispatch } from "../redux/store";
import { AuthState } from "../types/auth";
import { hasError, resetUser, setInitialize, setUser } from "../redux/slices/user";

type AuthProviderProps = {
  children: ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const dispatch = useDispatch();
  let check = false
  useEffect(() => {
    if(check)return
    const initialize = async () => {
      try {
        const accessToken = getCookie();
        if (accessToken) {
          setSession(accessToken);
          check=true
          const { user, token } = await LoginUser.getMe()
          setSession(token)
          setCookie(token)
          const userProfile: UserProfile = {
            id: user.id,
            user_name: user.user_name,
          };
          const _setUser: AuthState = {
            userProfile,
          };

          dispatch(setUser(_setUser));
        } else {
          dispatch(setInitialize(true));
        }
      } catch (error: any) {
        console.log(error)
        dispatch(setInitialize(true));
        dispatch(hasError(error));
        dispatch(resetUser())
        removeCookie()
      }
    };

    initialize();
  }, []);
  return <>{children}</>;
}
