import numeral from 'numeral';

numeral.register('locale', 'fa', {
  delimiters: {
      thousands: ',',
      decimal: '.'
  },
  abbreviations: {
      thousand: 'هزار',
      million: 'میلیون',
      billion: 'میلیارد',
      trillion: 'تریلیون'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: 'تومان'
  }
});

// switch between locales
numeral.locale('fa');

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0 $' : '0,0.00 $');
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0 %');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fToNumber(number: string | number) {
  return numeral(number).value();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00 a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
