import { AuthState } from '../types/auth';
import { useSelector } from '../redux/store';

const useAuth = () => {
  const user: AuthState = useSelector((state) => state.user);

  if (!user) throw new Error('Auth must be use inside AuthProvider');

  return user;
};

export default useAuth;
