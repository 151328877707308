import React, { memo } from "react";
import styles from "./styles.module.css";
import useSettings from "../../hooks/useSettings";
import { Stack } from "@mui/material";
// import { Switch } from '@mui/material';

const ReactThemeToggleButton: any = React.memo(() => {
  const { themeMode, onChangeMode } = useSettings();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <Stack sx={{p:0, m:0}}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={`${styles.container} ${
          themeMode === "dark" ? styles.IsDark : styles.IsLight
        }`}
        title={
          themeMode === "dark" ? "Activate light mode" : "Activate dark mode"
        }
        aria-label={
          themeMode === "dark" ? "Activate light mode" : "Activate dark mode"
        }
      >
        <input
          type="checkbox"
          checked={themeMode === "dark"}
          onChange={onChangeMode}
          value={themeMode === "dark" ? "light" : "dark"}
        />
        <div />
      </label>
    </Stack>
  );
});

export default memo(ReactThemeToggleButton);
