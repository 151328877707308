import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.dark;
  const COMMON_BLACK = theme.palette.common.black;

  const logo = (
    <Box sx={{ width: 100, height: 100, ...sx, }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 104 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
