import { memo } from "react";
// @mui
import { IconButton, Stack } from "@mui/material";
//
import { NavSectionProps } from "../type";
import NavList from "./NavList";
import ReactThemeToggleButton from "../../ToggleButtonTheme";
import SvgIconStyle from "../../SvgIconStyle";
import { useDispatch } from "../../../redux/store";
import { setLoginTest } from "../../../redux/slices/user";
import { Logout } from "../../../services/userApiServiceClickadu";
import { useSnackbar } from "notistack";
import { removeCookie } from "../../../utils/jwt";

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
} as const;

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const dispatch = useDispatch()
  const {enqueueSnackbar} = useSnackbar()
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{ bgcolor: "background.neutral", borderRadius: 1, px: 0.5 }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1, flex: 1 }}>
        <Stack direction="row-reverse" sx={{ flex: 1 }}>
          <Stack sx={{ ml: "auto", mr: 0 }}>
            <ReactThemeToggleButton />
          </Stack>
          {navConfig.map((group) => (
            <Stack
              key={group.subheader}
              direction="row"
              sx={{ mx: "auto" }}
              flexShrink={0}
            >
              {group.items.map((list) => (
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChildren={!!list.children}
                />
              ))}
            </Stack>
          ))}
          <Stack sx={{ mr: "auto", ml: 0 }}>
            <IconButton title="logout" onClick={async ()=>{
              try {
                const res = await Logout()
              } catch (error) {
                console.log(error)
              }
              dispatch(setLoginTest(false))
              removeCookie()
              enqueueSnackbar("You have left the site!",{variant:"info"})
            }}>
              <SvgIconStyle
                src={`/assets/icons/public/logouticon.svg`}
                sx={{ width: 25, height: 25, color:'#FF4842' }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
