import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Alert,
  Container,
  Typography,
  Link,
  Button,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
import { Link as RouterLink } from "react-router-dom";
import { PATH_AUTH } from "../../routes/paths";
import LoginForm from "../../sections/auth/LoginForm";
import { useState } from "react";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down("md")]: {
    paddingTop: "8rem",
  },
}));


// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [signupForm, SetSignupForm] = useState<boolean>(false);

  return (
    <Page title="login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>


        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Admin Panel
                </Typography>
              </Box>
            </Stack>
            <Alert severity="info" sx={{ mb: 3 }}>
              Please enter your username and password
            </Alert>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
